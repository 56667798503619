

































import { Component, Prop, Vue } from "vue-property-decorator";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

@Component({
  components: { FscInputDecimal },
})
export default class AutomaticPaymentTriggersTable extends Vue {
  public name = "AutomaticPaymentTriggersTable";

  @Prop({ default: () => [], type: Array })
  public items!: any;

  @Prop({ default: () => [], type: Array })
  protected automaticPaymentEvents!: Array<IBasicNamedDTO>;

  protected onDelete(index: number): void {
    this.$emit("on-delete", index);
  }

  protected onAdd(): void {
    this.$emit("on-add");
  }

  protected isLast(index: number): boolean {
    return this.items.length - 1 === index;
  }
}
