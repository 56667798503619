






















































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { Validations } from "vuelidate-property-decorators";
import Validation from "@/components/Validation.vue";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    FscCard,
    AbortButton,
    SaveButton,
    FscMultiselect,
    FscInputDecimal,
    Validation,
  },
})
export default class AutomaticPaymentCreateForm extends Vue {
  public name = "AutomaticPaymentCreateForm";

  @Prop({ default: () => [] })
  public licenseClasses!: any;

  @Prop({ default: () => [] })
  public automaticPaymentEvents!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public automaticPaymentEvent = {};
  public licenseClass = "";
  public amountInEur = "";

  public onAbort() {
    this.$emit("on-abort");
  }

  public onSubmit() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const data = {
      automaticPaymentEvent: this.automaticPaymentEvent,
      amountInEur: this.amountInEur,
      licenseClass: this.licenseClass,
    };
    this.$emit("on-submit", data);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      automaticPaymentEvent: { required },
      licenseClass: { required },
      amountInEur: { required },
    };
  }
}
